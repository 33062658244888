import React from "react";
import { IconBaseProps } from "react-icons";
import { CgCPlusPlus } from "react-icons/cg";
import {
  FaAndroid,
  FaAngular,
  FaAws,
  FaBootstrap,
  FaCss3,
  FaDocker,
  FaGitAlt,
  FaHtml5,
  FaJava,
  FaJenkins,
  FaJs,
  FaLinux,
  FaMarkdown,
  FaNodeJs,
  FaPhp,
  FaPython,
  FaReact,
  FaRust,
  FaSass,
  FaWordpress,
  FaYarn,
} from "react-icons/fa";
import { GrMysql } from "react-icons/gr";
import {
  SiC,
  SiCloudfoundry,
  SiGnubash,
  SiGo,
  SiHibernate,
  SiJquery,
  SiJupyter,
  SiKotlin,
  SiMongodb,
  SiNpm,
  SiOracle,
  SiScikitlearn,
  SiSpring,
  SiSpringboot,
  SiTypescript,
  SiVisualstudiocode,
} from "react-icons/si";

export const IconMap: { [key: string]: React.ReactElement<IconBaseProps> } = {
  FaPython: React.createElement(FaPython),
  FaJava: React.createElement(FaJava),
  CgCPlusPlus: React.createElement(CgCPlusPlus),
  SiC: React.createElement(SiC),
  SiGnubash: React.createElement(SiGnubash),
  FaJs: React.createElement(FaJs),
  SiTypescript: React.createElement(SiTypescript),
  SiKotlin: React.createElement(SiKotlin),
  SiGo: React.createElement(SiGo),
  FaRust: React.createElement(FaRust),
  FaPhp: React.createElement(FaPhp),
  FaHtml5: React.createElement(FaHtml5),
  FaCss3: React.createElement(FaCss3),
  FaSass: React.createElement(FaSass),
  FaMarkdown: React.createElement(FaMarkdown),
  SiSpring: React.createElement(SiSpring),
  SiSpringboot: React.createElement(SiSpringboot),
  FaReact: React.createElement(FaReact),
  FaAngular: React.createElement(FaAngular),
  FaNodeJs: React.createElement(FaNodeJs),
  SiJquery: React.createElement(SiJquery),
  FaBootstrap: React.createElement(FaBootstrap),
  FaWordpress: React.createElement(FaWordpress),
  SiHibernate: React.createElement(SiHibernate),
  SiScikitlearn: React.createElement(SiScikitlearn),
  GrMysql: React.createElement(GrMysql),
  SiOracle: React.createElement(SiOracle),
  SiMongodb: React.createElement(SiMongodb),
  FaGitAlt: React.createElement(FaGitAlt),
  FaDocker: React.createElement(FaDocker),
  SiNpm: React.createElement(SiNpm),
  FaYarn: React.createElement(FaYarn),
  FaJenkins: React.createElement(FaJenkins),
  FaAndroid: React.createElement(FaAndroid),
  FaAws: React.createElement(FaAws),
  SiCloudfoundry: React.createElement(SiCloudfoundry),
  FaLinux: React.createElement(FaLinux),
  SiJupyter: React.createElement(SiJupyter),
  SiVisualstudiocode: React.createElement(SiVisualstudiocode),
};
